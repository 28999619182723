import React from 'react'
import styled from '@emotion/styled'

import {
  space,
  color,
  width,
  height,
  fontSize,
  flex,
  alignSelf,
  order,
  lineHeight,
} from 'styled-system'

const Header = styled.header(
  {
    margin: 0,
    padding: 0,
    fontFamily: 'GothamRoundedMedium',
  },
  space,
  color,
  width,
  height,
  fontSize,
  flex,
  alignSelf,
  order,
  lineHeight
)

Header.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...flex.propTypes,
  ...alignSelf.propTypes,
  ...order.propTypes,
  ...lineHeight.propTypes,
}

const LargeHeader = ({ ...props }) => <Header {...props} fontSize={[4, 5, 6]} />

const MediumHeader = ({ ...props }) => (
  <Header {...props} fontSize={[3, 4, 5]} />
)

const SmallHeader = ({ ...props }) => (
  <Header {...props} fontSize={[3, 3, 3, 3]} />
)

export { Header, LargeHeader, MediumHeader, SmallHeader }
