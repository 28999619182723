import React from 'react'
import { IoLogoTwitter, IoLogoInstagram } from 'react-icons/io'

// import { Link } from "gatsby"
// import Image from '../components/image'
import SEO from '../components/seo'

import Box from '../components/box'
import Chiclet from '../components/svg/chiclet'
// import Download from '../components/svg/download'
import DownloadButton from '../components/downloadButton'
// import P from '../components/p'

import Renderer from '../components/three/renderer'

import { MediumHeader } from '../components/h'

// import theme from '../components/theme'
// const { colors } = theme

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Box>
      <Box
        p={4}
        css={{
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: 99,
          // transform: 'translate3d(0, -100%, 0)',
          textAlign: 'center',
        }}
      >
        <Chiclet
          css={{
            width: 'auto',
            height: '100px',
          }}
        />
      </Box>
      <Box
        p={4}
        css={{
          position: 'absolute',
          top: '100%',
          width: '100%',
          zIndex: 99,
          transform: 'translate3d(0, -100%, 0)',
          textAlign: 'center',
        }}
      >
        <MediumHeader
          css={{
            // position: 'absolute',
            // top: '100%',
            // zIndex: 99,
            // transform: 'translate3d(0, -150%, 0)',
            color: '#fff',
            textAlign: 'center',
          }}
          p={4}
        >
          Make Augmented Reality out of anything.
        </MediumHeader>
        <DownloadButton
          url="https://apps.apple.com/us/app/ar-makr/id1434081130"
          css={{
            width: '200px',
            height: 'auto',
          }}
        />
        <Box pt={2}>
          <a
            css={{ color: '#fff', fontSize: '44px', marginRight: '8px' }}
            href="https://twitter.com/ARMakrApp"
          >
            <IoLogoTwitter />
          </a>
          <a
            css={{ color: '#fff', fontSize: '44px' }}
            href="https://www.instagram.com/ar.makr.app/"
          >
            <IoLogoInstagram />
          </a>
        </Box>
      </Box>
      <Renderer
        css={{
          width: '100%',
          height: '100vh',
          // backgroundColor: colors.purple,
          backgroundColor: '#000',
        }}
      />
      {/* <LargeHeader mb={4}>Mattis Commodo</LargeHeader>
      <MediumHeader mb={4}>Mattis Commodo</MediumHeader>
      <SmallHeader mb={4}>Mattis Commodo</SmallHeader>
      <P mb={4}>
        Nullam quis risus eget urna mollis ornare vel eu leo. Cras justo odio,
        dapibus ac facilisis in, egestas eget quam.
      </P>
      <P mb={4}>
        Integer posuere erat a ante venenatis dapibus posuere velit aliquet.
        Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh,
        ut fermentum massa justo sit amet risus. Vivamus sagittis lacus vel
        augue laoreet rutrum faucibus dolor auctor. Etiam porta sem malesuada
        magna mollis euismod.
      </P>
      <P>
        <a href="https://apps.apple.com/us/app/ar-makr/id1434081130">
          AR Makr on the App Store
        </a>
      </P> */}
    </Box>

    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}
  </>
)

export default IndexPage
