/* eslint-env browser */
import React, { PureComponent } from 'react'
import * as Three from 'three'
// import { Group } from 'three'
import PropTypes from 'prop-types'
import { withContentRect } from 'react-measure'
import * as R from 'ramda'
import raf from 'raf'

// Assets
import issImg from './iss.png'
import issShadowImg from './iss_shadow.png'
// import logoImg from './logo.png'
import moonImg from './moon.jpg'
import satelliteImg from './satellite.png'
import satelliteShadowImg from './satellite_shadow.png'

import rocketImg from './rocket.png'
import rocketShadowImg from './rocket_shadow.png'

import tableImg from './wood.jpg'

// Set game loop fps
const FRAME_INTERVAL = 1.0 / 60

const PIXEL_DENSITY =
  typeof window !== 'undefined' ? window.devicePixelRatio : 1.0

// Polyfill requestAnimationFrame
raf.polyfill()

const makeShadowedSpriteGroup = (texture, shadowTexture, width, height) => {
  const geometry = new Three.PlaneGeometry(width, height, 1)

  const material = new Three.MeshBasicMaterial({
    map: texture,
    side: Three.DoubleSide,
    transparent: true,
    depthTest: true,
    depthWrite: false,
  })

  const mesh = new Three.Mesh(geometry, material)
  mesh.castShadow = false
  mesh.customDepthMaterial = new Three.MeshDepthMaterial({
    depthPacking: Three.RGBADepthPacking,
    map: texture,
    alphaTest: 0.5,
    // side: Three.DoubleSide,
    depthTest: true,
    depthWrite: true,
  })

  const shadowGeometry = new Three.PlaneGeometry(width, height, 1)

  const shadowMaterial = new Three.MeshBasicMaterial({
    // color: '#FF0000',
    map: texture,
    transparent: true,
    side: Three.DoubleSide,
    depthTest: true,
    depthWrite: true,
    shadowSide: Three.DoubleSide,
  })

  shadowMaterial.opacity = 0

  const shadowMesh = new Three.Mesh(shadowGeometry, shadowMaterial)

  shadowMesh.customDepthMaterial = new Three.MeshDepthMaterial({
    depthPacking: Three.RGBADepthPacking,
    map: shadowTexture,
    alphaTest: 0.99,
    side: Three.DoubleSide,
    shadowSide: Three.DoubleSide,
  })

  shadowMesh.rotation.x = Math.PI / 2.0

  shadowMesh.castShadow = true

  const group = new Three.Group()
  group.add(mesh)
  group.add(shadowMesh)

  return group
}

class Renderer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      width: -1,
      height: -1,
    }
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    const newWidth = R.pathOr(-1, ['contentRect', 'bounds', 'width'], nextProps)
    const newHeight = R.pathOr(
      -1,
      ['contentRect', 'bounds', 'height'],
      nextProps
    )

    const { width } = this.state

    if (width === -1) {
      // Bootstrap three.js environment
      this.initThree(newWidth, newHeight)

      // Start render loop
      this.renderThree()
    }

    this.setState({
      width: newWidth,
      height: newHeight,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { width: prevWidth, height: prevHeight } = prevState
    const { width, height } = this.state

    if (
      width !== -1 &&
      (prevWidth !== width || prevHeight !== height) &&
      this.renderer
    ) {
      // console.log('Resize player')
      this.resizeThree(width, height)
    }
    // const { contentRect } = this.props
    // console.log(width, height)
    // this.setState({ dimensions: contentRect.bounds })
  }

  componentWillUnmount() {
    // Cleanup.
    this.camera = null
    this.scene = null
    this.renderer.forceContextLoss()
    this.renderer.context = null
    this.renderer = null
  }

  setupMoon() {
    const moonGeometry = new Three.SphereGeometry(1.0, 64, 64)
    this.moonTexture = new Three.TextureLoader().load(moonImg)
    const moonMaterial = new Three.MeshBasicMaterial({
      map: this.moonTexture,
      // color: 0xffff00,
      side: Three.DoubleSide,
      depthTest: true,
      depthWrite: true,
      // transparent: true,
    })

    this.moon = new Three.Mesh(moonGeometry, moonMaterial)
    this.moon.castShadow = true
    this.moon.receiveShadow = true
    this.scene.add(this.moon)
    this.moon.position.z = 0

    const cubeGeometry = new Three.BoxGeometry(2.0, 2.0, 2.0, 1.0, 1.0, 1.0)
    this.cubeMoon = new Three.Mesh(cubeGeometry, moonMaterial)
    this.cubeMoon.castShadow = true
    this.cubeMoon.receiveShadow = true
    this.cubeMoon.position.x = -4.5
    this.cubeMoon.position.y = -3
    this.cubeMoon.position.z = -2
    this.scene.add(this.cubeMoon)

    const coneGeometry = new Three.ConeGeometry(1.0, 2.0, 32, 32, false)
    this.coneMoon = new Three.Mesh(coneGeometry, moonMaterial)
    this.coneMoon.castShadow = true
    this.coneMoon.receiveShadow = true
    this.coneMoon.position.x = 4.5
    this.coneMoon.position.y = -3
    this.coneMoon.position.z = -2
    this.scene.add(this.coneMoon)
  }

  setupRocket() {
    this.rocketTexture = new Three.TextureLoader().load(rocketImg)

    this.rocketShadowTexture = new Three.TextureLoader().load(rocketShadowImg)

    this.rocketGroup = makeShadowedSpriteGroup(
      this.rocketTexture,
      this.rocketShadowTexture,
      1.5,
      1.5
    )

    // Rotate shadow mesh
    this.rocketGroup.children[1].rotation.z = Math.PI / 2.0
    this.rocketGroup.children[1].rotation.y = -Math.PI / 2.0

    this.scene.add(this.rocketGroup)
    this.rocketGroup.position.x = 2.0
    this.rocketGroup.position.z = 0.0
  }

  setupSatellite() {
    this.satelliteTexture = new Three.TextureLoader().load(satelliteImg)
    this.satelliteShadowTexture = new Three.TextureLoader().load(
      satelliteShadowImg
    )

    this.satelliteGroup = makeShadowedSpriteGroup(
      this.satelliteTexture,
      this.satelliteShadowTexture,
      1.0,
      1.0
    )

    this.scene.add(this.satelliteGroup)
  }

  setupSpaceStation() {
    this.issTexture = new Three.TextureLoader().load(issImg)
    this.issShadowTexture = new Three.TextureLoader().load(issShadowImg)

    this.issGroup = makeShadowedSpriteGroup(
      this.issTexture,
      this.issShadowTexture,
      1.5,
      1.5
    )

    this.issGroup.position.x = 1
    this.issGroup.position.z = -5

    this.scene.add(this.issGroup)
  }

  resizeThree(width, height) {
    // console.log('Resize:', width, height)

    if (this.camera.isOrthographicCamera) {
      this.camera.left = width / -2
      this.camera.right = width / 2
      this.camera.top = height / 2
      this.camera.bottom = height / -2
    } else {
      this.camera.aspect = width / height
    }

    this.camera.updateProjectionMatrix()

    this.renderer.setSize(width, height)
    // this.renderer.setViewport(0, 0, width, height)
  }

  initThree(width, height) {
    // console.log('Init three:', width, height)
    this.clock = new Three.Clock()
    this.lastTick = 0

    this.scene = new Three.Scene()
    this.scene.fog = new Three.Fog('#000', 5.0, 20.0)

    // this.camera = new Three.OrthographicCamera(
    //   width / -2, // left
    //   width / 2, // right
    //   height / 2, // top
    //   height / -2, // bottom
    //   -500, // near
    //   1000 // far
    // )

    this.camera = new Three.PerspectiveCamera(45, width / height, 0.1, 1000)
    // this.camera.lookAt(this.scene.position)

    // const geometry = new Three.BoxGeometry(500, 500, 500)
    // const material = new Three.MeshBasicMaterial({ color: 0x00ff00 })
    // // const material = new Three.MeshStandardMaterial({
    // //   metalness: 0,
    // //   roughness: 0.5,
    // // })

    // this.cube = new Three.Mesh(geometry, material)
    // this.scene.add(this.cube)

    // this.logoTexture = new Three.TextureLoader().load(logoImg)
    // const logoGeometry = new Three.PlaneGeometry(1, 1, 32)
    // const logoMaterial = new Three.MeshBasicMaterial({
    //   map: this.logoTexture,
    //   // color: 0xffff00,
    //   side: Three.DoubleSide,
    //   transparent: true,
    // })

    // this.logoPlane = new Three.Mesh(logoGeometry, logoMaterial)
    // this.scene.add(this.logoPlane)
    // this.logoPlane.position.z = 6.0
    // this.logoPlane.position.z = 2.0

    this.setupMoon()
    this.setupRocket()
    this.setupSatellite()
    // this.setupSpaceStation()

    const groundGeometry = new Three.PlaneGeometry(40, 40, 32)

    this.tableTexture = new Three.TextureLoader().load(tableImg)

    const groundMaterial = new Three.MeshBasicMaterial({
      map: this.tableTexture,
      // color: '#621CEB',
      // color: '#4e16bb',
      // color: '#FF00FF',
      // color: 0xffff00,
      side: Three.DoubleSide,
      depthTest: true,
      depthWrite: true,
      transparent: false,
    })

    this.tableTexture.wrapS = Three.RepeatWrapping
    this.tableTexture.wrapT = Three.RepeatWrapping
    this.tableTexture.offset.set(0, 0)
    this.tableTexture.repeat.set(8, 4)

    // const groundMaterial = new Three.MeshPhongMaterial({
    //   color: '#621CEB',
    //   // color: '#FF00FF',
    //   side: Three.DoubleSide,
    // })

    const shadowGeometry = new Three.PlaneGeometry(1000, 1000, 32)
    const shadowMaterial = new Three.ShadowMaterial({
      opacity: 0.25,
      side: Three.DoubleSide,
    })

    this.groundPlane = new Three.Mesh(groundGeometry, groundMaterial)
    // this.groundPlane.receiveShadow = true
    // this.scene.add(this.logoPlane)
    this.scene.add(this.groundPlane)

    this.groundPlane.rotation.x = Math.PI / 2.0
    this.groundPlane.position.y = -4.0
    // this.logoPlane.position.z = 2.0

    this.shadowPlane = new Three.Mesh(shadowGeometry, shadowMaterial)
    this.shadowPlane.receiveShadow = true

    this.scene.add(this.shadowPlane)

    this.shadowPlane.rotation.x = Math.PI / 2.0
    this.shadowPlane.position.y = -3.0

    const color = 0xffffff
    const intensity = 1

    this.light = new Three.DirectionalLight(color, intensity)
    this.light.castShadow = true
    this.light.position.set(0, 10, 0)
    this.light.target.position.set(0, 0, 0)
    // this.light.shadow = new Three.LightShadow(
    //   new Three.PerspectiveCamera(50, 1, 10, 2500)
    // )
    this.light.shadow.bias = 0.0001
    this.light.shadow.mapSize.width = 2048
    this.light.shadow.mapSize.height = 2048

    // const d = 200

    // this.light.shadowCameraLeft = -d
    // this.light.shadowCameraRight = d
    // this.light.shadowCameraTop = d
    // this.light.shadowCameraBottom = -d

    // this.light.shadowCameraFar = 1000
    // this.light.shadowDarkness = 0.2

    this.scene.add(this.light)
    this.scene.add(this.light.target)

    // this.ambientLight = new Three.AmbientLight('#FFFFFF', 2.0)
    // this.scene.add(this.ambientLight)

    // const cameraHelper = new Three.CameraHelper(this.light.shadow.camera)
    // this.scene.add(cameraHelper)

    // const helper = new Three.DirectionalLightHelper(this.light)
    // this.scene.add(helper)

    this.camera.position.z = 10

    this.renderer = new Three.WebGLRenderer({
      alpha: true,
      antialias: true,
      canvas: this.canvas,
      depth: true,
      preserveDrawingBuffer: false,
      // stencil: false,
    })

    this.renderer.setPixelRatio(PIXEL_DENSITY)
    this.renderer.autoClear = true
    this.renderer.setSize(width, height)

    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = Three.PCFSoftShadowMap
  }

  // eslint-disable-next-line class-methods-use-this
  updateThree() {
    const delta = this.clock.getDelta()
    const speed = 0.05

    if (!this.theta) {
      this.theta = Math.PI / 8.0
    }
    // console.log('Update three')
    if (this.moon) {
      // this.moon.rotation.x += 0.01
      this.moon.rotation.y += speed * delta
    }

    this.theta += speed * delta

    const radius = 3.0

    this.satelliteGroup.position.x = Math.sin(this.theta * 4) * radius
    this.satelliteGroup.position.z = Math.cos(this.theta * 4) * radius

    this.rocketGroup.position.x = Math.sin(this.theta * 8) * 1.5
    this.rocketGroup.position.y = Math.cos(this.theta * 8) * 1.5
    this.rocketGroup.rotation.z = -this.theta * 8 - Math.PI / 2.0

    // this.issGroup.position.y = 1.5 + Math.sin(this.theta * 10) / 10.0
  }

  renderThree() {
    this.rafHandle = raf(rafTime => {
      if (!this.renderer) {
        return // This will break the run loop. That's okay?
      }
      // console.log(rafTime, this.lastTick)
      if (rafTime - this.lastTick > FRAME_INTERVAL) {
        // this.resizeSceneIfNeeded()
        this.updateThree()
        this.renderer.render(this.scene, this.camera)
        this.lastTick = rafTime
      }
      this.renderThree()
    })
  }

  render() {
    const { contentRect, measure, measureRef, ...rest } = this.props
    return (
      <div
        ref={measureRef}
        {...rest}
        css={{
          overflow: 'hidden',
        }}
      >
        {/* <img
          src={testImg}
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        /> */}
        <canvas
          ref={node => {
            this.canvas = node
          }}
          css={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    )
  }
}

Renderer.propTypes = {
  contentRect: PropTypes.shape({}),
  measureRef: PropTypes.func.isRequired,
}

Renderer.defaultProps = {}

export default withContentRect([
  'client',
  'offset',
  'scroll',
  'bounds',
  'margin',
])(Renderer)
