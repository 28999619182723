import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'

import Download from './svg/download'

const DownloadButton = ({ url }) => {
  const [{ hovered }, setState] = useState({ hovered: false })
  const config = { mass: 1, tension: 200, friction: 10 }
  const props = useSpring({
    config,
    transform: hovered ? 'scale3d(1.1, 1.1, 1.1)' : 'scale3d(1.0, 1.0, 1.0)',
  })

  return (
    <animated.a
      rel="noopener noreferrer"
      target="_blank"
      href={url}
      onFocus={() => {}}
      onMouseOver={() => setState({ hovered: true })}
      onMouseLeave={() => setState({ hovered: false })}
      style={{
        display: 'inline-block',
        background: 'ref',
        ...props,
      }}
    >
      <Download css={{ width: '200px', height: 'auto' }} />
    </animated.a>
    // <animated.a
    //   ref={ref}
    //   rel="noopener noreferrer"
    //   target="_blank"
    //   href={url}
    //   onFocus={() => {}}
    //   // onMouseOver={() => setState({ hovered: true })}
    //   // onMouseLeave={() => setState({ hovered: false })}
    //   css={{
    //     cursor: 'pointer',
    //   }}
    //   style={props}
    // >
    //   <Download css={{ width: '200px', height: 'auto' }} />
    // </animated.a>
  )
}

DownloadButton.propTypes = {
  url: PropTypes.string,
}

DownloadButton.defaultProps = {
  url: '',
}

export default DownloadButton
