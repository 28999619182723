import React from 'react'

const SVG = React.forwardRef((props, ref) => (
  <svg id="Layer_4" viewBox="0 0 1024 1024" ref={ref} {...props}>
    <rect
      x="0.25"
      y="0.16"
      width="1024"
      height="1024"
      rx="234.34"
      ry="234.34"
      css={{
        fill: '#621ceb',
      }}
    />
    <path
      d="M763.61,706.47C749.5,613,724.19,577.76,676.91,548.09c0,0,34.87-56.74,14.81-148.83,0,0-1.29-8.33-13-6.9s-37.28,3.37-36.59,12.41c.61,8.17,11.79,82-6.23,112.26-.39.25-4,7.08-8.56,8-9.45,1.94-9.73-3.24-10.89-6.71-2.44-7.36-21.09-79.27-21.63-81.2-3.06-14.7-16.85-12.59-31.59-8.55-26.48,7.27-30.52,7.35-24.35,31.85,7,27.66,61.49,258.87,67.62,277.54s19.47,16.75,33,14.17,30.5-7.5,29.33-21.29c-.79-9.56-19.64-84.48-23.89-100.48-2.06-7.78-2.1-18.43,6.78-20.63,12-3,14.78,3.54,19.5,10.53,14.38,21.34,31.94,109,45.87,113.62,9.71,3.23,43.11-1.38,46.62-9.93,1-2.51.35-12.37.1-15.55-.05-.66-.12-1.29-.23-1.95"
      css={{
        fill: '#FFF',
      }}
    />
    <path
      d="M254.08,500.71c-9.25-26.33-21.23-24-42.5-20.13-15.76,2.88-22.47,9.31-24.09,11.14,0,0-.08,0-.11.07-2.85,2.31-4.88,8-5.24,12.85-1.25,16.73,6.82,53.13,7.32,80.49.19,10.16-7.56,12.52-11.63,13.28-4.78.87-12.67,1.12-16.62-7.88-10.23-23.42-16.3-69.27-23.78-80.28-8.14-12-18.53-14.07-41.25-8.49s-28.83,16.17-30,23.86c-3.46,23.3,11.92,233.64,27.7,306.75,2.11,9.8,5,17.22,15.56,17.5,12.37.33,20.69-.83,28.7-3.82,10.17-3.8,11.18-10,10.55-17.89l-10.15-73.45s-3.58-24.1-6-40.24,6.31-19.06,13.18-20.23c10.66-1.79,18.05,3.88,20.61,15.2l13.82,60.06c2.42,15.83,21.54,14.61,31.11,12.87,10.59-1.91,17.47-5.44,17.68-16.28l-8.74-64.67c.24-8.06,1.26-17.35,14-19.65,11-2,17.59,4,20.34,14.9,3.2,12.77,10.48,52.1,10.48,52.1l10,53.8c3.1,16.08,14.54,15.48,20,14.68,25.53-3.8,30.37-6.47,27.18-29.92-5.36-39.4-52.8-242.7-68.23-286.62"
      css={{
        fill: '#FFF',
      }}
    />
    <path
      d="M532.93,577.25c-4.06-35.55-82-262.73-95-289.34s-23.34-32.55-68.86-19.35c-28.88,8.37-41.2,14.85-46.12,24.32v0c-1.29,2.49-24.79,80.32-25.17,82.38l0,.13h0a50.11,50.11,0,0,0-.66,9.25c.14,20,29.78,221.6,40,276.9,6.39,34.54,12.46,36.62,35,34.56,17.43-1.59,36.64-7.5,37.67-20L426,622.47c2.14.79,3.93,2.53,5.61,5.8,6.24,12.17,9,31.92,13.85,41.48,5.7,11.23,19.78,11.18,32.68,7.82,10.78-2.81,26.86-4.9,31-16.23l22.75-75.07a18.42,18.42,0,0,0,1-9"
      css={{
        fill: '#000',
      }}
    />
    <path
      d="M438.28,287.86c-13-26.62-23.35-32.55-68.88-19.35-42.75,12.4-49.27,20.61-49.12,40.62s29.79,221.61,40,276.9c6.37,34.53,12.27,35.7,34.8,33.63,17.43-1.59,36.81-6.57,37.84-19.08.89-10.57-3.21-26.52-3.48-39.05-.18-9.25,2.38-12,9.89-14s11.72-2.06,15.46,5.24c6.24,12.16,9,31.92,13.86,41.47,5.68,11.23,19.94,12.12,32.83,8.76,13.11-3.42,33.89-7.13,31.77-25.77C529.2,541.65,451.26,314.47,438.28,287.86Zm3.32,206.6c-4.7,1-16.88,2.8-22.61,4-11,2.33-12.88-12.32-12.88-12.32s-21.68-122.1-23-132.28c-1-7.49,6.29-10.83,11.59-12.24,11.59-3.08,15.46,2,16.85,5.23,5,11.64,34.08,109.08,38.18,121.12C456.14,486.87,451.76,492.31,441.6,494.46Z"
      css={{
        fill: '#FFF',
      }}
    />
    <path
      d="M910,398.32c7.79-18.17,8.42-60.12,6.81-83.17-2-28.76-12.12-57-19.14-70.17-14.44-27-50.07-77.25-108.5-67.82-29,4.69-50.31,12.39-54.44,29.92l-22.81,75.5c-4.34,18.44,56.87,300.85,59.32,313,3.27,16.35,10,24.25,44.65,18.47,15.15-2.52,23.4-5.43,25.39-12.09l21.42-72.67a11.08,11.08,0,0,0,.88-1.52C868,545,874,567.7,877.44,583.94c3.41,16,15.58,15.6,35.08,12.11a68.9,68.9,0,0,0,21.61-8l23.36-76.24-.76-.22c1.65-2.7,2.2-6.86,2-13.77-.08-3.56-.89-79-48.77-99.51"
      css={{
        fill: '#000',
      }}
    />
    <path
      d="M865.39,360c-2.91,19.86-7.78,21.92-21.39,24.75-5.56,1.15-15.41,1.52-18.65-13.64,0,0-27.44-121.46-27.11-129.85s31.7-11.67,49.33,23.06c6.73,13.24,11.88,23.95,15.07,45.23,3.93,26.32,5,35.08,2.75,50.45m44.34,36.73c9.28-24.32,8.43-60.1,6.82-83.16-2-28.77-12.13-57.06-19.14-70.17-14.43-27-50.07-77.24-108.49-67.82-29,4.67-50.32,12.38-54.45,29.91-4.35,18.44,56.87,300.85,59.3,313,3.29,16.35,10,24.24,44.67,18.47,21.51-3.58,29.18-7.91,24.61-22.75,0,0-8.79-40.34-12.27-58.81-3.15-16.65,6.23-17,14.12-18.71,11.46-2.49,18.59,3.77,21,13.2,4.46,17.31,10.59,40.52,14.09,57,3.41,16.05,15.22,13.72,34.73,10.25s24.23-1.88,23.76-20.84C958.51,496.24,959,418.06,909.73,396.71Z"
      css={{
        fill: '#FFF',
      }}
    />
  </svg>
))

export default SVG
